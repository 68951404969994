import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';

import {
  MobileClickableBackground,
  MobileFakeButton,
  DesktopClickableBackground,
  DesktopFakeButton,
  MapButton
} from './styles';

const messages = defineMessages({
  mapSearchLinkText: {
    id: 'map_search.map_search_link.text',
    description: 'Text of the link that opens the map search page',
    defaultMessage: 'Map'
  },
  mapSearchLinkAriaLabel: {
    id: 'map_search.map_search_link.aria_label',
    description: 'Aria label of the link that opens the map search page',
    defaultMessage: 'Map Search'
  }
});

const MapSearchLinkBlockComponent = ({
  backgroundImgUrl,
  linkUrl,
  forMobile = true,
  onClick,
  children: svg,
  className,
  intl
}) => {
  return forMobile ? (
    <MobileClickableBackground
      data-testid="MapSearchLink-Mobile"
      backgroundImgUrl={backgroundImgUrl}
      href={linkUrl}
      onClick={onClick}
      aria-label={kyruusFormatMessage(intl, messages.mapSearchLinkAriaLabel)}
      className={className}
    >
      <MobileFakeButton>
        <KyruusFormattedMessage {...messages.mapSearchLinkText} />
        {svg}
      </MobileFakeButton>
    </MobileClickableBackground>
  ) : (
    <DesktopClickableBackground
      data-testid="MapSearchLink-Desktop"
      backgroundImgUrl={backgroundImgUrl}
      href={linkUrl}
      onClick={onClick}
      aria-label={kyruusFormatMessage(intl, messages.mapSearchLinkAriaLabel)}
      className={className}
    >
      <DesktopFakeButton>
        <KyruusFormattedMessage {...messages.mapSearchLinkText} />
        {svg}
      </DesktopFakeButton>
    </DesktopClickableBackground>
  );
};

export const MapSearchLinkButton = injectIntl(({ linkUrl, onClick, intl }) => (
  <MapButton
    data-testid="MapSearchLink"
    href={linkUrl}
    onClick={onClick}
    aria-label={kyruusFormatMessage(intl, messages.mapSearchLinkAriaLabel)}
  >
    <KyruusFormattedMessage {...messages.mapSearchLinkText} />
  </MapButton>
));

const MapSearchLinkBlock = injectIntl(MapSearchLinkBlockComponent);
export default MapSearchLinkBlock;
